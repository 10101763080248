.center-container {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  text-align: center;
}

body {
  background: #ccc;
}

.form-container {
  max-width: 40em;
  margin: 20px auto;
  padding: 20px 50px;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0,0,0,0.24);
  border-radius: 5px;
}

.form-container h1 {
  font-size: 28px;
  text-align: center;
}

.form-container h1.confirmation-title {
  line-height: 1.4;
  margin-bottom: 30px;
}

.form-container .confirmation p {
  text-align: center;
}

.form-container .field-pad-right {
  padding-right: 15px;
  padding-top: 10px;
}

.form-container .field-pad-top {
  padding-top: 10px;
}

.error-message { 
  padding: 20px;
  border: 1px solid rgb(190, 60, 60);
  color: rgb(190, 60, 60);
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
}

.error-message.small {
  padding: 5px;
  font-size: 13px;
}

.required {
  color: rgb(129, 31, 31);
  font-weight: bold;
  font-size: 12px;
}